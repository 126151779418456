import last from 'lodash/last'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import debounce from 'lodash/debounce'
import first from 'lodash/first'
import moment from 'moment'
import { Badge } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import { emojiFor, speciesFor } from '../../lib/helpers'
import { fetchPatientsAction, patientsSelector, PatientsState } from '../../hasura/slices/patients'
import { UsersState, usersSelector } from '../../hasura/slices/users'
import { Patients_patients } from '../../hasura/graphQlQueries/types/Patients'

export enum PatientSearchParentType {
  SwitchPatientModal,
  CaseList,
}

interface Props {
  handleSelectedPatient: (patient?: Patients_patients) => void
  organizationId?: number
  filterPatientId?: number
  parent: PatientSearchParentType
}

export default (props: Props) => {
  const dispatch = useDispatch()

  const { accessToken }: UsersState = useSelector(usersSelector)
  const { loading, patients }: PatientsState = useSelector(patientsSelector)

  const [input, setInput] = useState<string>('')

  useEffect(() => {
    if (!accessToken || !props.organizationId) return

    dispatch(fetchPatientsAction(accessToken, props.organizationId, input))
  }, [accessToken, props.organizationId, input])

  const handleInputChange = debounce((newValue: string) => setInput(newValue), 500)

  const patientOptions = patients.map((patient) => {
    const dicomServerPatientId = last(patient.dicom_server_patient_id.split('-'))
    return {
      value: patient.id,
      label: patient.display_name,
      dicomServerPatientId,
      species: patient.species,
      latestCaseCreatedAt: moment(first(patient.cases)?.created_at),
    }
  })

  return (
    <Select
      className="max-width-300px w-100 data-hj-allow"
      classNamePrefix="react-select"
      isLoading={loading}
      onChange={(option: any) => props.handleSelectedPatient(patients.find((p) => p.id === option?.value))}
      onInputChange={handleInputChange}
      options={patientOptions}
      placeholder="Search patient..."
      value={patientOptions.find((p) => p.value === props.filterPatientId) || null}
      isClearable
      formatOptionLabel={(option: any, metadata: any) => (
        <div className="position-relative d-flex align-items-center justify-content-between">
          <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} className="m-0">
            <span className="mr-1 mb-0 text-l">{emojiFor(speciesFor(option.species))}</span>
            <span className={`text-m m-0 ${option.disabled ? 'text-muted' : ''}`}>{option.label}</span>
            {option.dicomServerPatientId &&
              (metadata.context !== 'value' || props.parent == PatientSearchParentType.SwitchPatientModal) && (
                <span className="ml-2 text-muted text-xxs">ID {option.dicomServerPatientId}</span>
              )}
          </p>

          {props.parent !== PatientSearchParentType.SwitchPatientModal && metadata.context !== 'value' && (
            <Badge
              className="text-xxs regular"
              color={moment().subtract(14, 'days').isBefore(option.latestCaseCreatedAt) ? 'success' : 'light'}
            >
              Updated {option.latestCaseCreatedAt.format('MM/DD/YY')}
            </Badge>
          )}
        </div>
      )}
    />
  )
}
