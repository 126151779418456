import React, { useEffect } from 'react'
import Select from 'react-select'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import { Badge } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import CONFIG from '../../config'
import { Option } from '../../lib/helpers'
import { UsersState, usersSelector } from '../../hasura/slices/users'

import {
  fetchConsultationPredictionsForOrganizationAction,
  predictionsSelector,
  PredictionsState,
} from '../../hasura/slices/predictions'

export enum PatientSearchParentType {
  SwitchPatientModal,
  CaseList,
}

interface Props {
  enterpriseId?: number
  selectedConditionOption: (option: OptionWithCount | null) => void
  selectedCondition: OptionWithCount | null
}

const MIN_CONDITIONS_TO_DISPLAY_FEATURE = CONFIG.IS_DEVELOPMENT ? 1 : 10
const MIN_CONSULTATIONS_TO_INCLUDE_CONDITION = CONFIG.IS_DEVELOPMENT ? 1 : 3

export interface OptionWithCount extends Option {
  count: number
}

export default (props: Props) => {
  const dispatch = useDispatch()

  const { accessToken }: UsersState = useSelector(usersSelector)
  const { consultationPredictionsForOrganization }: PredictionsState = useSelector(predictionsSelector)

  useEffect(() => {
    if (!accessToken || !props.enterpriseId || consultationPredictionsForOrganization.length) return

    dispatch(fetchConsultationPredictionsForOrganizationAction(accessToken, props.enterpriseId))
  }, [accessToken, props.enterpriseId])

  const options: OptionWithCount[] = sortBy(uniqBy(consultationPredictionsForOrganization, 'condition.id'), 'condition.display_name')
    .filter((c) => c.condition)
    .map((c) => ({
      value: c.condition!.id,
      label: c.condition!.display_name,
      count: consultationPredictionsForOrganization.filter((c2) => c.condition!.id == c2.condition!.id).length,
    }))
    .filter((c) => c.count >= MIN_CONSULTATIONS_TO_INCLUDE_CONDITION)

  if (options.length < MIN_CONDITIONS_TO_DISPLAY_FEATURE) {
    return null
  }

  return (
    <Select
      classNamePrefix="react-select"
      className="max-width-300px w-100 data-hj-allow"
      placeholder="Search condition..."
      onChange={(option: OptionWithCount | null) => props.selectedConditionOption(option)}
      isClearable
      value={props.selectedCondition}
      options={options}
      formatOptionLabel={(option: OptionWithCount, metadata: any) => {
        const isSelected = metadata.context === 'value'
        return (
          <div className="position-relative d-flex align-items-center justify-content-between w-100">
            <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} className="m-0">
              <span className={`text-m m-0`}>{option.label}</span>
            </p>

            <Badge className={`text-xxs regular ${isSelected ? 'semibold ml-1' : ''}`} color={isSelected ? 'primary' : 'light'}>
              {option.count} {isSelected ? 'specialist found cases' : 'cases'}
            </Badge>
          </div>
        )
      }}
    />
  )
}
