import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import extend from 'lodash/extend'
import flatten from 'lodash/flatten'
import last from 'lodash/last'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import AiAssessmentModal from '../ai-assessment/index'
import CONFIG from '../../config'
import CasesTable from './CasesTable'
import ConditionSearch, { OptionWithCount } from './ConditionSearch'
import OrganizationSelect from '../common/OrganizationSelect'
import OverflowSwitch from './OverflowSwitch'
import PatientSearch, { PatientSearchParentType } from './PatientSearch'
import ReportModal from './ReportModal'
import Setup from '../setup'
import StatBadge from '../stat/StatBadge'
import TablePagination from '../common/TablePagination'
import UploadDicomsModal from './UploadDicomsModal'
import UserCasesTableSubscriptions from '../subscriptions/UserCasesTable'
import { CasesState, fetchCasesAction, casesSelector } from '../../hasura/slices/cases'
import { Cases_cases_consultations } from '../../hasura/graphQlQueries/types/Cases'
import { FETCH_CASES_LIMIT } from '../../hasura/graphQlQueries/Case'
import { Option, isAdmin, isLocalhost, isOnFullConsultPlan, isSuperAdmin, isUser, medicalImageKeysForCase } from '../../lib/helpers'
import { OrganizationEvent } from '../../lib/organizationEventTypes'
import { Organizations_organizations } from '../../hasura/graphQlQueries/types/Organizations'
import { Patients_patients } from '../../hasura/graphQlQueries/types/Patients'
import { usersSelector, UsersState } from '../../hasura/slices/users'

import {
  fetchOrganizationsAction,
  insertOrganizationEventAction,
  insertOrganizationEventForUserAction,
  organizationsSelector,
  OrganizationsState,
} from '../../hasura/slices/organizations'

import {
  consultationsSelector,
  ConsultationsState,
  fetchConsultationPdfAction,
  removePresignedUrlAction,
  fetchImagesAction,
  Patient,
} from '../../hasura/slices/consultations'

enum TimeSlice {
  Last24Hours = '🗓️ Last 24 Hours',
  Last7Days = '🗓️ Last 7 Days',
  All = '🗓️ All',
}

const createdAtForTimeSlice = (str: string): string => {
  if (str.includes('Last 24 Hours')) {
    return moment().subtract(1, 'days').utc().format()
  } else if (str.includes('Last 7 Days')) {
    return moment().subtract(7, 'days').utc().format()
  } else {
    return moment().startOf('year').subtract(5, 'years').utc().format()
  }
}

export default function CaseList() {
  const dispatch = useDispatch()

  const timeSlices = [TimeSlice.Last24Hours, TimeSlice.Last7Days, TimeSlice.All].map((timeSlice) => ({
    value: timeSlice,
    label: timeSlice,
  }))

  const { accessToken, user, role, billingEnabled }: UsersState = useSelector(usersSelector)
  const { cases, totalCases }: CasesState = useSelector(casesSelector)
  const { organizations }: OrganizationsState = useSelector(organizationsSelector)
  const { presignedUrl, presignedCaseImageUrls }: ConsultationsState = useSelector(consultationsSelector)

  const [cursor, setCursor] = useState<number | undefined>(undefined)
  const [displayAiAssessmentCaseId, setDisplayAiAssessmentCaseId] = useState<number | undefined>()
  const [displayUploadDicomsModal, setDisplayUploadDicomsModal] = useState(false)
  const [organization, setOrganization] = useState<Organizations_organizations | null>()
  const [patientId, setPatientId] = useState<number | undefined>(undefined)
  const [condition, setCondition] = useState<OptionWithCount | null>(null)
  const [previousCursors, setPreviousCursors] = useState<number[]>([])
  const [privateNotes, setPrivateNotes] = useState<string | undefined>()
  const [reportModalConsultation, setReportModalConsultation] = useState<Cases_cases_consultations | null>(null)
  const [selectedTimeSlice, setSelectedTimeSlice] = useState<Option | undefined>()

  const sortEnabled = Boolean((isUser(role) || organization) && selectedTimeSlice?.value !== TimeSlice.All)

  /*
    Effects
  */

  useEffect(() => {
    if (organization || !isUser(role)) fetchCases()
  }, [organization, cursor, patientId, selectedTimeSlice, condition])

  useEffect(() => {
    if (!user || !accessToken) return

    const timeSlice = timeSlices[isLocalhost() ? 2 : isSuperAdmin(role) ? 0 : 1]
    setSelectedTimeSlice(timeSlice)

    if (!isUser(role) && !organizations.length) {
      dispatch(fetchOrganizationsAction(accessToken, user.organization.enterprise.id))
    } else if (isUser(role)) {
      setOrganization(user.organization)
    }
  }, [user, accessToken])

  useEffect(() => {
    return () => {
      dispatch(removePresignedUrlAction())
    }
  }, [])

  useEffect(() => {
    if (!cases.length) return

    const keys = flatten(cases.map(medicalImageKeysForCase))
    dispatch(fetchImagesAction(keys))
  }, [cases])

  /*
    Methods
  */

  const fetchCases = () => {
    if (!selectedTimeSlice) return

    dispatch(
      fetchCasesAction(
        accessToken,
        createdAtForTimeSlice(selectedTimeSlice.value),
        user?.organization.enterprise?.id,
        organization?.id,
        cursor,
        patientId,
        condition?.value
      )
    )
  }

  const handleSelectOrganization = (option: Option | null) => {
    resetPagination()
    setPatientId(undefined)
    setOrganization(organizations.find((o) => o.id === option?.value))
  }

  const viewConsultationReport = (
    consultation: Cases_cases_consultations,
    key: string,
    patient: Patient,
    privateNotes?: string,
    viewerLink?: string
  ) => {
    setPrivateNotes(privateNotes)
    setReportModalConsultation(consultation)
    dispatch(fetchConsultationPdfAction(key, patient, viewerLink))
  }

  const handleSelectedPatient = (patient?: Patients_patients) => {
    resetPagination()
    setPatientId(patient?.id)
    if (!patient) return

    setCondition(null)
    dispatch(
      insertOrganizationEventForUserAction(accessToken, OrganizationEvent.SearchedPatient, user?.organization.id, {
        patient_id: patient.id,
      })
    )
  }

  const handleSelectedTimeSlice = (option: any) => {
    resetPagination()
    setSelectedTimeSlice(option)
    dispatch(
      insertOrganizationEventForUserAction(accessToken, OrganizationEvent.UpdatedTimeframe, user?.organization.id, {
        timeframe: option.value,
      })
    )
  }

  /*
    Pagination Methods
  */

  const resetPagination = () => {
    setCursor(undefined)
    setPreviousCursors([])
  }

  const handleClickedNewer = () => {
    setCursor(last(previousCursors))
    setPreviousCursors(previousCursors.slice(0, previousCursors.length - 1))
  }

  const handleClickedOlder = () => {
    setCursor(last(cases)?.id)
    if (cursor) setPreviousCursors(previousCursors.concat(cursor))
  }

  if (isUser(role) && user?.organization.dicom_servers.length === 0) {
    return <Setup />
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-start mb-2">
        <div className="d-flex align-items-center">
          <h4 className="bold mr-4 mb-0">Cases</h4>

          {isAdmin(role) && user?.organization.enterprise.enterprise_admin_has_overflow_control && <OverflowSwitch />}
        </div>

        <StatBadge />
      </div>

      {user && isUser(role) && <UserCasesTableSubscriptions refresh={fetchCases} />}

      {presignedUrl && <ReportModal consultation={reportModalConsultation} privateNotes={privateNotes} url={presignedUrl} />}

      {displayUploadDicomsModal && <UploadDicomsModal setDisplayUploadDicomsModal={setDisplayUploadDicomsModal} />}

      {displayAiAssessmentCaseId && (
        <AiAssessmentModal
          handleClose={(didEdit: boolean) => {
            setDisplayAiAssessmentCaseId(undefined)
            if (didEdit) fetchCases()
          }}
          id={displayAiAssessmentCaseId}
        />
      )}

      <div>
        <div className="mb-2 d-flex flex-column gap-10px">
          {!isUser(role) && (
            <OrganizationSelect
              organization={organization}
              organizations={organizations}
              handleSelectOrganization={handleSelectOrganization}
            />
          )}

          {organization && (
            <div className="d-flex gap-20px">
              <PatientSearch
                filterPatientId={patientId}
                handleSelectedPatient={handleSelectedPatient}
                organizationId={organization.id}
                parent={PatientSearchParentType.CaseList}
              />

              <ConditionSearch
                enterpriseId={user?.organization.enterprise.id}
                selectedCondition={condition}
                selectedConditionOption={(option: OptionWithCount | null) => {
                  resetPagination()
                  setPatientId(undefined)
                  setCondition(option)
                  const event = {
                    organization_id: user?.organization.id,
                    organization_event_type_id: OrganizationEvent.SearchedCondition,
                    additional_data: { condition: option?.label },
                  }
                  dispatch(insertOrganizationEventAction(accessToken, event))
                }}
              />
            </div>
          )}
        </div>

        <div className="mr-5 d-flex align-items-start">
          <Select
            classNamePrefix="muted"
            className="mr-3"
            onChange={handleSelectedTimeSlice}
            options={timeSlices}
            value={selectedTimeSlice}
          />

          {totalCases > FETCH_CASES_LIMIT && !patientId && !condition && (
            <TablePagination
              loading={false}
              cases={cases}
              clickedNewer={handleClickedNewer}
              clickedNewest={resetPagination}
              clickedOlder={handleClickedOlder}
              cursor={cursor}
              previousCursors={previousCursors}
              rowsPerPage={FETCH_CASES_LIMIT}
              totalCount={totalCases}
            />
          )}
        </div>
      </div>

      <div>
        <CasesTable
          blurAiFindings={!CONFIG.IS_DEVELOPMENT && (isOnFullConsultPlan(user) || !user?.organization.enterprise.ai_enabled)}
          fetchCases={fetchCases}
          handleClickedAiAssessment={(id: number) => setDisplayAiAssessmentCaseId(id)}
          isUser={isUser(role)}
          organizationId={organization?.id}
          shouldPopBillingModal={isUser(role) && user?.organization.enable_billing_flow === true && billingEnabled === false}
          sortEnabled={sortEnabled}
          stripeId={user?.related_accounts?.stripe_profile?.id}
          uploadDicom={() => setDisplayUploadDicomsModal(true)}
          viewConsultationReport={viewConsultationReport}
          // @ts-ignore
          data={cases.map((c) => {
            const presigned_urls = presignedCaseImageUrls.filter((url) =>
              c.medical_images.find((i) => url.includes(i?.aws_s3_url || ''))
            )
            return extend({}, c, { presigned_urls })
          })}
        />

        {totalCases > FETCH_CASES_LIMIT && !patientId && !condition && (
          <TablePagination
            loading={false}
            cases={cases}
            clickedNewer={handleClickedNewer}
            clickedNewest={resetPagination}
            clickedOlder={handleClickedOlder}
            cursor={cursor}
            previousCursors={previousCursors}
            rowsPerPage={FETCH_CASES_LIMIT}
            totalCount={totalCases}
          />
        )}
      </div>
    </div>
  )
}
